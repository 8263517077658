import React, { Component } from 'react';
import Panel from '../Panel/Panel.js';
import { withTranslation } from 'react-i18next';
class CategoryHolder extends Component {
  state = {
    images: [],
    loading: true,
  };
  async componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/get_categories.php`;
    const response = await fetch(url);
    const data = await response.json();
    this.setState({ images: data, loading: false });
  }
  render() {
    let br = null;
    let lng = this.state.images.length;
    let size = 4;

    if (lng % 3 == 2) {
      br = lng - 2;
    }
    if (lng % 3 == 1) {
      br = lng - 4;
    }

    const Panels = this.state.images.map((image, i) => {
      const { i18n } = this.props;
      return (
        <Panel
          key={image.id_kategorije}
          link={`kategorija/${image.id_kategorije}`}
          size={br !== null ? (br <= i ? 6 : 4) : 4}
          name={i18n.language == 'rs' ? image.ime_sr : image.ime_en}
          text={i18n.language == 'rs' ? image.opis_sr : image.opis_en}
          url={`${process.env.REACT_APP_API_URL}/images/category_images/${image.ime_slike}`}
        />
      );
    });
    if (this.state.loading) {
      return <div>Loading</div>;
    } else {
      return (
        <div className="container-fluid">
          <div className="row row-centered">{Panels}</div>
        </div>
      );
    }
  }
}

export default withTranslation()(CategoryHolder);
