import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navigation';

import img from '../img/404.jpg';
import './NotFound.css';
class NotFound extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="p-160">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${img})`
            }}
          >
            <div className="col content my-auto">
              <h1>404</h1>
              <h2>Page not found</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
