import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navigation';
import CategoryHolder from '../components/PanelHolder/CategoryHolder';
import Footer from '../components/Footer/Footer';

class Kategorije extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="row no-gutters galerija-slika mh-260"></div>
        <div>
          <CategoryHolder />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Kategorije;
