import React from "react";
import { useForm } from "react-hook-form";
import "./Contact.css";
import { withTranslation } from "react-i18next";

function Contact({ t }) {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    fetch(`https://ateljevitraz.com/Atelje/api/send_mail.php`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json().then(function (data) {
          if (data.status === "success") {
            document.getElementById("kontaktForma").classList.add("hide");
            setTimeout(() => {}, 1000);
            document.getElementById("succ-msg").classList.remove("hidden");
          } else {
          }
        });
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };

  return (
    <div className="contact-section " data-aos="fade">
      <div className="container-fluid">
        <div className="row justify-content-center formbg p-160">
          <div className="col-md-7 text-area mt-5 mb-4">
            <div className="row mb-5 ">
              <div className="col-12 ">
                <h1 className="site-section-heading text-center">
                  {t("Contact.Title1")}
                </h1>
              </div>
            </div>

            <div className="row text-white text-fancy">
              <div className="col-lg-8 mb-5">
                <label
                  id="succ-msg"
                  className="accentText hidden"
                  htmlFor="subject"
                >
                  {t("Contact.Success")}
                </label>
                <form id="kontaktForma" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label className="text-black" htmlFor="fname">
                        {t("Contact.Name")}
                      </label>
                      <input
                        type="text"
                        name="fname"
                        className="form-control"
                        ref={register({ required: true })}
                      />
                      {errors.fname && (
                        <p style={{ color: "#ff6a00" }}>
                          {t("Contact.Required")}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="text-black" htmlFor="lname">
                        {t("Contact.Lastname")}
                      </label>
                      <input
                        type="text"
                        name="lname"
                        className="form-control"
                        ref={register({ required: true })}
                      />
                      {errors.lname && (
                        <p style={{ color: "#ff6a00" }}>
                          {t("Contact.Required")}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row form-group ">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        ref={register({
                          required: true,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && (
                        <p style={{ color: "#ff6a00" }}>
                          {t("Contact.Bademail")}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="subject">
                        {t("Contact.Title")}
                      </label>
                      <input
                        type="subject"
                        name="subject"
                        className="form-control"
                        ref={register({ required: true, minLength: 8 })}
                      />
                      {errors.subject && (
                        <p style={{ color: "#ff6a00" }}>
                          {t("Contact.Badtitle")}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="message">
                        {t("Contact.Message")}
                      </label>
                      <textarea
                        name="message"
                        cols="30"
                        rows="7"
                        className="form-control"
                        placeholder={t("Contact.Messageus")}
                        ref={register({ required: true, minLength: 10 })}
                      ></textarea>
                      {errors.message && (
                        <p style={{ color: "#ff6a00" }}>
                          {t("Contact.Badmessage")}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12 text-right mt-3">
                      <input
                        type="submit"
                        value={t("Contact.Send")}
                        className="btn btn-primary py-2 px-4 text-white"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 col-xl-3 ml-auto white-text">
                <div className="mb-3">
                  <p className="mb-0 font-weight-bold">
                    {t("Contact.Address")}
                  </p>
                  <p className="mb-4">Novi Beograd Nehurova&nbsp;109/111</p>
                  <p className="mb-4">{t("Contact.Belgrade")}</p>
                  <p className="mb-0 font-weight-bold">{t("Contact.Phone")}</p>
                  <p className="accentText">+381&nbsp;69/&nbsp;1011&nbsp;008</p>
                  <p className="accentText mb-4">
                    +381&nbsp;63/&nbsp;230&nbsp;307
                  </p>
                  <p className="mb-0 font-weight-bold">
                    {t("Contact.Emailaddress")}
                  </p>
                  <p className="mb-0">
                    <a
                      className="accentLink"
                      href="mailto:nina@ateljevitraz.com"
                    >
                      nina@ateljevitraz.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Contact);
