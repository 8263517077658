import React, { Component } from 'react';
import img from '../../img/Nina.jpg';
import { withTranslation, Trans } from 'react-i18next';

class Bio extends Component {
  render() {
    const { t } = this.props;
    return (
      <section
        id="about"
        className="pt-5 pb-5 ds ms page_about parallax section_padding_top_130 section_padding_bottom_75 table_section table_section_md columns_margin_bottom_30 white-text"
        style={{
          // backgroundPosition: '50% -5px'
          backgroundColor: '#0f1223'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="with_frame_image">
                <img
                  src={img}
                  alt="Nina"
                  style={{ width: '300px', position: 'relative', zIndex: 5 }}
                  className="rounded bioImg img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0">
              <h1 className="site-section-heading2 text-left">
                {t('Bio.Title1')}
              </h1>
              <p className="text-left text-fancy">
                <Trans i18nKey="Bio.About1">About Author</Trans>
              </p>
              <h1 className="site-section-heading2 text-left">
                {t('Bio.Title2')}
              </h1>
              <p className="text-left text-fancy">
                <Trans i18nKey="Bio.About2">About Studio</Trans>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Bio);
