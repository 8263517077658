import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
import NotFound from './containers/NotFound';
import Kategorije from './containers/Kategorije';
import Galerija from './containers/Galerija';
import Kategorija from './containers/Kategorija';
import Kontakt from './containers/Kontakt';
export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/onama" exact component={About} />
      <Route path="/kategorije" exact component={Kategorije} />
      <Route path="/kontakt" exact component={Kontakt} />
      <Route path="/kategorija/:id" component={Kategorija} />
      <Route path="/galerija/:id" component={Galerija} />
      <Route component={NotFound} />
    </Switch>
  );
}
