import React, { Component } from "react";

class Map extends Component {
  render() {
    return (
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1415.5709070118085!2d20.38462942014298!3d44.79829844591115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6fe46b57b65d%3A0xf3d55a5926ebe8d5!2z0J3QtdGF0YDRg9C-0LLQsCAxMTEsINCR0LXQvtCz0YDQsNC0IDExMDcw!5e0!3m2!1ssr!2srs!4v1682176869200!5m2!1ssr!2srs"
          style={{
            width: "100%",

            height: "300px",
          }}
          frameBorder="0"
          allowFullScreen=""
        ></iframe>
      </div>
    );
  }
}

export default Map;
