import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navigation';
import GalleryHolder from '../components/PanelHolder/GalleryHolder';
import Footer from '../components/Footer/Footer';

class Kategorija extends Component {
  render() {
    return (
      <div>
        <Navbar />

        <div>
          <GalleryHolder match={this.props.match.params} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Kategorija;
