import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slika1 from './img/1.png';
import slika2 from './img/2.png';
import slika3 from './img/3.png';
import './Slider.css';
class Slider extends Component {
  render() {
    return (
      <Carousel
        nextIcon={<i className="fas fa-2x fa-chevron-right"></i>}
        prevIcon={<i className="fas fa-2x fa-chevron-left"></i>}
        fade={true}
      >
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-img"
            src={slika2}
            alt="First slide"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-img"
            src={slika3}
            alt="Third slide"
          />

          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-img"
            src={slika1}
            alt="Third slide"
          />

          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Slider;
