import React, { Component } from 'react';
import Panel from '../Panel/Panel.js';
import { withTranslation } from 'react-i18next';
import TextPanel from '../../components/TextPanel/TextPanel';
import Gallery from '../Gallery/Gallery';
import { withRouter } from 'react-router-dom';

class GalleryHolder extends Component {
  state = {
    images: [],
    loading: true,
  };
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/get_galleries.php?id_kategorije=${this.props.match.params.id}`;
    const response = await fetch(url);
    const data = await response.json();
    this.setState({ data: data, loading: false });
  }
  render() {
    if (!this.state.data || !this.state.data.info)
      return (
        <div className="row no-gutters galerija-slika mh-260"></div>
      ); /*'<p style={{ color: 'white' }}> ERROR 404 : PAGE NOT FOUND</p>;*/

    if (this.state.data.galleries.length == 1) {
      this.props.history.push({
        pathname: `/galerija/${this.state.data.galleries[0].id_galerije}`,
      });
      /*
        return (
          <Gallery match={{ id: this.state.data.galleries[0].id_galerije }} />
        );*/
    }

    const { i18n } = this.props;
    const { info } = this.state.data;
    let br = null;
    let lng = this.state.images.length;

    let size = 4;

    if (lng % 3 == 2) {
      br = lng - 2;
    }
    if (lng % 3 == 1) {
      br = lng - 4;
    }

    const Panels = this.state.data.galleries.map((image, i) => {
      return (
        <Panel
          key={image.id_galerije}
          link={`/galerija/${image.id_galerije}`}
          size={br !== null ? (br <= i ? 6 : 4) : 4}
          name={i18n.language == 'rs' ? image.ime_sr : image.ime_en}
          text={i18n.language == 'rs' ? image.opis_sr : image.opis_en}
          url={`${process.env.REACT_APP_API_URL}/images/gallery_images/${image.ime_slike}`}
        />
      );
    });
    if (this.state.loading) {
      return <div>Loading</div>;
    } else {
      return (
        <div className="min-height-fix">
          <div className="row no-gutters galerija-slika mh-260 p-relative">
            <div class="centered2">
              {' '}
              {i18n.language == 'rs' ? info.ime_sr : info.ime_en}
            </div>
            {i18n.language == 'rs' ? info.opis_sr : info.opis_en}
          </div>

          <div className="container-fluid">
            <div className="row row-centered">{Panels}</div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(withTranslation()(GalleryHolder));
