import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navigation';
import PanelHolder from '../components/PanelHolder/PanelHolder';
import Footer from '../components/Footer/Footer';
import TextPanel from '../components/TextPanel/TextPanel';
import Carousel from '../components/Carousel/Slider';
import { withTranslation } from 'react-i18next';

import TextPanelCustom from '../components/TextPanel/TextPanelCustom';

class Home extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <Navbar />
        <div>
          <Carousel />
          <PanelHolder />

          <TextPanelCustom
            url="/kategorije"
            title={t('Home.Title')}
            class="panel1"
          ></TextPanelCustom>
          <TextPanelCustom
            url="/onama"
            title={t('Home.Title2')}
            class="panel2"
          ></TextPanelCustom>
          <TextPanelCustom
            url="/kontakt"
            title={t('Home.Title3')}
            class="panel3"
          ></TextPanelCustom>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
