import React, { Component } from 'react';
import Panel from '../Panel/Panel.js';
import { withTranslation } from 'react-i18next';
class PanelHolder extends Component {
  state = {
    images: [],
    loading: true,
  };
  async componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/get_top3.php`;
    const response = await fetch(url);
    const data = await response.json();
    console.log(data);
    this.setState({ images: data, loading: false });
  }

  render() {
    const { i18n } = this.props;
    const Panels = this.state.images.map((image, i) => (
      <Panel
        key={image.id_galerije}
        id={image.id_galerije}
        name={i18n.language == 'rs' ? image.ime_sr : image.ime_en}
        text={i18n.language == 'rs' ? image.opis_sr : image.opis_en}
        url={`${process.env.REACT_APP_API_URL}/images/gallery_images/${image.ime_slike}`}
        link={`/galerija/${image.id_galerije}`}
      />
    ));
    if (this.state.loading) {
      return <div>Loading</div>;
    } else {
      return <div className="row pb-3">{Panels}</div>;
    }
  }
}
export default withTranslation()(PanelHolder);
