import React, { Component } from 'react';

import Navbar from '../components/Navbar/Navigation';

import Footer from '../components/Footer/Footer';
import Gallery from '../components/Gallery/Gallery';

class Galerija extends Component {
  render() {
    return (
      <div>
        <Navbar />

        <div>
          <Gallery match={this.props.match.params} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Galerija;
