import React, { Component } from 'react';
import './Panel.css';
import { Redirect } from 'react-router';
class Panel extends Component {
  state = {
    redirect: false,
  };
  style = {
    width: '100%',
    filter: 'brightness(70%)',
    minWidth: '100%',
    minHeight: '100%',
  };
  style2 = {
    maxHeight: '400px',
    objectFit: 'cover',
    overflow: 'hidden',
  };
  handleOnClick = () => {
    this.setState({ redirect: true });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.props.link,
            state: this.props,
          }}
        />
      );
    }

    return (
      <div
        onClick={this.handleOnClick}
        className={`panelcol col-md-${
          this.props.size ? this.props.size : '4'
        } p-0`}
        style={this.style2}
      >
        <img
          alt={this.props.name}
          src={this.props.url}
          style={this.style}
        ></img>
        <div className="centered panelDesc">{this.props.name}</div>
      </div>
    );
  }
}

export default Panel;
