import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class TextPanel extends Component {
  render() {
    return (
      <div>
        <div className="textBox">
          <div className="row">
            <div className="col text-center">
              <h1>
                <strong>{this.props.title}</strong>
              </h1>
              <div className="line"></div>
              <p
                className="text-fancy"
                dangerouslySetInnerHTML={{ __html: this.props.children }}
              ></p>
              {this.props.url ? (
                <div className="div_p">
                  <Link
                    to={this.props.url}
                    className="btn btn-primary btn-lg"
                    href={this.props.url}
                  >
                    {this.props.urlName}
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextPanel;
