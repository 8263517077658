import React, { Component } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';
import { withTranslation } from 'react-i18next';
import TextPanel from '../../components/TextPanel/TextPanel';

const PhotoItem = ({ image, group, text, id }) => (
  <div
    style={{
      maxWidth: '340px',
      width: '340px',
      margin: '5px',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    }}
  >
    <LightgalleryItem group={group} src={image} subHtml={`#caption${id}`}>
      <div style={{ position: 'relative' }}>
        <img
          alt={text}
          src={image}
          className="img-fluid"
          style={{
            width: '100%',
            minHeight: '260px',
            maxHeight: '260px',
            objectFit: 'cover',
          }}
        />
        <div className="overlayIcon">
          <i className="iconZoom fas fa-search"></i>
        </div>
      </div>
      <div id={`caption${id}`} style={{ display: 'none' }}>
        <h3>{text}</h3>
      </div>
    </LightgalleryItem>
  </div>
);
const VideoItem = ({ link, group, text, id }) => (
  <div
    style={{
      maxWidth: '340px',
      width: '340px',
      margin: '5px',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    }}
  >
    <LightgalleryItem
      group={group}
      src={`https://www.youtube.com/watch?v=${link}`}
      subHtml={`#caption${id}`}
    >
      <div style={{ position: 'relative' }}>
        <img
          alt={text}
          src={`https://img.youtube.com/vi/${link}/0.jpg`}
          className="img-fluid"
          style={{
            width: '100%',
            minHeight: '260px',
            maxHeight: '260px',
            objectFit: 'cover',
          }}
        />
        <div className="overlayIconYT">
          <i className="iconZoomYT fab fa-youtube"></i>
        </div>
      </div>
      <div id={`caption${id}`} style={{ display: 'none' }}>
        <h3>{text}</h3>
      </div>
    </LightgalleryItem>
  </div>
);
class Gallery extends Component {
  state = {
    data: null,
    loading: true,
  };
  async componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/get_images.php?id_galerije=${this.props.match.id}`;
    const response = await fetch(url);
    const data = await response.json();
    this.setState({ data: data, loading: false });
  }
  render() {
    if (!this.state.data || !this.state.data.info)
      return (
        <div className="row no-gutters galerija-slika mh-260"></div>
      ); /*<p style={{ color: 'white' }}> ERROR 404 : PAGE NOT FOUND</p>;*/
    const { i18n } = this.props;
    const { info } = this.state.data;
    const Panels = this.state.data.images.map((image, i) => {
      if (image.video) {
        return (
          <VideoItem
            key={image.id_slike}
            id={image.id_slike}
            group="default"
            link={image.ime_slike}
            text={i18n.language == 'rs' ? image.ime_sr : image.ime_en}
          />
        );
      } else {
        return (
          <PhotoItem
            key={image.id_slike}
            id={image.id_slike}
            group="default"
            image={`${process.env.REACT_APP_API_URL}/images/${image.ime_slike}`}
            text={i18n.language == 'rs' ? image.ime_sr : image.ime_en}
          />
        );
      }
    });
    return (
      <div className="min-height-fix">
        <div className="row no-gutters galerija-slika mh-260 p-relative">
          <div class="centered2">
            {' '}
            {i18n.language == 'rs' ? info.ime_sr : info.ime_en}
          </div>
          {i18n.language == 'rs' ? info.opis_sr : info.opis_en}
        </div>

        <LightgalleryProvider
          lightgallerySettings={{
            download: false,
            autoplayControls: true,
            autoplay: true,
            fourceAutoplay: true,
            plugins: ['lg-thumbnail.js', 'lg-video.js'],
            pause: 500,
          }}
        >
          <div className="d-flex flex-wrap justify-content-center mt-3 mb-3">
            {Panels}
          </div>
        </LightgalleryProvider>
      </div>
    );
  }
}

export default withTranslation()(Gallery);
