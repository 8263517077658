import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import sitemap from './sitemap.xml';
class Footer extends Component {
  handleClick = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  handleLang = () => {
    const { i18n } = this.props;
    if (i18n.language == 'en') i18n.changeLanguage('rs');
    else i18n.changeLanguage('en');
    window.location.reload();
  };
  render() {
    const { i18n, t } = this.props;
    return (
      <footer className="page-footer font-small unique-color-dark">
        <div
          style={{
            backgroundColor: 'rgb(25 52 78)',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
          }}
        >
          <div className="container p-3">
            <p className="inLine">
              <a
                href="https://www.facebook.com/ateljevitraz/"
                rel="noopener noreferrer"
                target="_blank"
                className="social-link"
              >
                <i className="fab fa-2x fa-facebook-f text-gray mr-3 ml-3"> </i>
              </a>
            </p>

            <p className="inLine">
              <a href="#" className="social-link">
                <i className="fab fa-2x fa-instagram text-gray mr-3 ml-3"> </i>
              </a>
            </p>

            <p className="inLine">
              <a href="mailto:nina@ateljevitraz.com" className="social-link">
                <i className="fas fa-2x fa-envelope text-gray mr-3 ml-3"> </i>
              </a>
            </p>
          </div>
        </div>

        <div
          className="footer-copyright text-center text-gray"
          style={{ backgroundColor: '#161c35' }}
        >
          <a
            href="http://www.ateljevitraz.com/public/sitemap.xml"
            className="ml-4 text-uppercase font-weight-bold align-middle grayLink"
            style={{ float: 'left', fontSize: '0.5rem', lineHeight: '24px' }}
          >
            {t('Sitemap')}
          </a>
          <span className="cinzel">
            © Copyright&nbsp;
            <Link to="/" className="accentLink">
              Atelje Vitraž
            </Link>
          </span>
          <a
            href="#"
            onClick={this.handleClick}
            className="mr-4 text-uppercase font-weight-bold align-middle grayLink"
            style={{ float: 'right', fontSize: '0.5rem', lineHeight: '24px' }}
          >
            {t('Footer.Top')}
          </a>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
