import React, { Component } from 'react';
import img1 from '../../img/ONAMA01.jpg';
import img2 from '../../img/vitraz2.jpg';
import img3 from '../../img/stakla.png';
import img4 from '../../img/lampa1.jpg';
import img5 from '../../img/lampa2.jpg';
import { withTranslation, Trans } from 'react-i18next';

import './AboutPanel.css';

class AboutPanel extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <section id="projects" className="projects-section white-text">
          <div className="row no-gutters  mb-lg-0 about1 mh-600 p-100">
            <div className="col-lg-6 ">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left text-area">
                    <h1 className="site-section-heading2 text-left">
                      {t('Bio.Title1')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="Bio.About1">About Author</Trans>
                    </p>
                    <h1 className="site-section-heading2 text-left">
                      {t('Bio.Title2')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="Bio.About2">About Studio</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row no-gutters about2 mh-600 my-3">
            <div className="offset-lg-6 col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right text-area">
                    <h1 className="site-section-heading2 text-left">
                      {t('About.Title1')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="About.Text1">Text</Trans>
                    </p>

                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row no-gutters about3 mh-600 my-3">
            <div className="offset-lg-6 col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right text-area">
                    <h1 className="site-section-heading2 text-left">
                      {t('About.Title2')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="About.Text2">Text</Trans>
                    </p>

                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters about4 mh-600 my-3">
            <div className="offset-lg-6 col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 text-center text-lg-right text-area mt-4 mb-4">
                    <h1 className="site-section-heading2 text-left">
                      {t('About.Title3')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="About.Text3">Text</Trans>
                    </p>

                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters about5 mh-600 my-3">
            <div className="offset-lg-6 col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 text-center text-lg-right text-area mt-200">
                    <h1 className="site-section-heading2 text-left">
                      {t('About.Title4')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="About.Text4">Text</Trans>
                    </p>

                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters about6 mh-600 mt-3">
            <div className="col-lg-5 order-lg-first ">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 text-center text-lg-right text-area mt-200">
                    <h1 className="site-section-heading2 text-left">
                      {t('About.Title5')}
                    </h1>
                    <p className="text-left text-fancy">
                      <Trans i18nKey="About.Text5">Text</Trans>
                    </p>

                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(AboutPanel);
