import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './TextPanelCustom.css';

class TextPanelCustom extends Component {
  render() {
    return (
      <div>
        <div className="textBox pt-2 pb-2 p-16">
          <Link
            to={this.props.url}
            className={`row textBoxLink ${this.props.class}`}
          >
            <div className="col text-center my-auto">
              <h1>
                <strong>{this.props.title}</strong>
              </h1>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default TextPanelCustom;
